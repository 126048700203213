<template>
  <div id="app">
    <router-view/>
  </div>
</template>


<script>
  export default {
    name: 'BindDevice',
    data() {
      return {
        sn:'',
        mobile:'',
        imgSrc: require('@/assets/img/ax_hd2.png'),
        code:'',
        time: '获取验证码',
      }
    },
    metaInfo() {
      return {
        title: '',
      };
    },
    created(){
     
    },
    mounted(){
      //记录ios进入的第一个页面
      this.$localStorage('WxCodeUrl',location.href)
    },
    methods: {
      
    },
  }
  

</script>
<script>
  //解决android下显示的问题 同样 在app.vue 中也写下面这行自运行函数 写入script 里面即可
  (function () {
    if (
      typeof WeixinJSBridge == "object" &&
      typeof WeixinJSBridge.invoke == "function"
    ) {
      handleFontSize();
    } else {
      if (document.addEventListener) {
        document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
      } else if (document.attachEvent) {
        document.attachEvent("WeixinJSBridgeReady", handleFontSize);
        document.attachEvent("onWeixinJSBridgeReady", handleFontSize);
      }
    }
    function handleFontSize() {
      // 设置网页字体为默认大小
      WeixinJSBridge.invoke("setFontSizeCallback", { fontSize: 2 });
      // 重写设置网页字体大小的事件
      WeixinJSBridge.on("menu:setfont", function () {
        WeixinJSBridge.invoke("setFontSizeCallback", { fontSize: 2 });
      });
    }
  })()
</script>
<style lang="scss">

//引入图标库
@font-face {
  font-family: 'iconfont';  /* Project id 4109306 */
  src: url('//at.alicdn.com/t/c/font_4109306_r4tr1lmwi1k.woff2?t=1686535521346') format('woff2'),
       url('//at.alicdn.com/t/c/font_4109306_r4tr1lmwi1k.woff?t=1686535521346') format('woff'),
       url('//at.alicdn.com/t/c/font_4109306_r4tr1lmwi1k.ttf?t=1686535521346') format('truetype');
}

.iconfont{
    font-family:"iconfont" !important;
    font-size:16px;font-style:normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;}

body {
  -webkit-text-size-adjust: 100% !important;
}
</style>
